import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_VALUES_MODAL } from "../models/ModalInitialValue";
import { ModalWorkOrder } from "../models/ModalWorkOrder";

const initialState: {
	modalWorkOrderSelected: ModalWorkOrder;

	assetCodes: any;

	searchAssetName: string;

	openDialogRemoveWorkOrderAttachments: boolean;
} = {
	modalWorkOrderSelected: INITIAL_VALUES_MODAL,

	assetCodes: {},

	searchAssetName: "",

	openDialogRemoveWorkOrderAttachments: false,
};

export const WorkOrderModalsReducer = createSlice({
	name: "__",
	initialState,
	reducers: {
		// ================= Modal WorkOrder =====================

		setAssetCodes(state, { payload }) {
			state.assetCodes = payload;
		},

		setSearchAssetName(state, { payload }) {
			state.searchAssetName = payload;
		},

		setmodalWorkOrderSelected(state, { payload }) {
			state.modalWorkOrderSelected.Comments = payload;
		},

		setTypeCode(state, { payload }) {
			state.modalWorkOrderSelected.TypeCode = payload;
		},
		setComments(state, { payload }) {
			state.modalWorkOrderSelected.Comments = payload;
		},
		setTemplateTitle(state, { payload }) {
			state.modalWorkOrderSelected.TemplateTitle = payload;
		},
		setWorkOrderPlannedHours(state, { payload }) {
			state.modalWorkOrderSelected.WorkOrderPlannedHours = payload;
		},
		setWorkOrderNumberOfWorkers(state, { payload }) {
			state.modalWorkOrderSelected.WorkOrderNumberOfWorkers = payload;
		},
		setWorkOrderOpenedBy(state, { payload }) {
			state.modalWorkOrderSelected.WorkOrderOpenedBy = payload;
		},
		setWorkOrderOpenTimestamp(state, { payload }) {
			state.modalWorkOrderSelected.WorkOrderOpenTimestamp = payload;
		},
		setWorkOrderName(state, { payload }) {
			state.modalWorkOrderSelected.WorkOrderName = payload;
		},
		setTitle(state, { payload }) {
			state.modalWorkOrderSelected.Title = payload;
		},
		setAssetName(state, { payload }) {
			state.modalWorkOrderSelected.AssetName = payload;
		},
		setWorkOrderId(state, { payload }) {
			state.modalWorkOrderSelected.WorkOrderId = payload;
		},
		setWorkOrderConsumedHours(state, { payload }) {
			state.modalWorkOrderSelected.ConsumedHours = payload;
		},
		setWorkOrderCloseTimestamp(state, { payload }) {
			state.modalWorkOrderSelected.CloseTimestamp = payload;
		},

		setAssetId(state, { payload }) {
			state.modalWorkOrderSelected.AssetId = payload;
		},
		setDownHours(state, { payload }) {
			state.modalWorkOrderSelected.DownHours = payload;
		},
		setCraftId(state, { payload }) {
			state.modalWorkOrderSelected.CraftId = payload;
		},
		setAttachmentsLinked(state, { payload }) {
			state.modalWorkOrderSelected.AttachmentsLinked = payload;
		},
		setWorkOrderStatusCode(state, { payload }) {
			state.modalWorkOrderSelected.WorkOrderStatusCode = payload;
		},
		setOpenDialogRemoveWorkOrderAttachments(state, { payload }) {
			state.openDialogRemoveWorkOrderAttachments = payload;
		},
	},
});
