import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { AgGridReact } from "ag-grid-react";
import {
	ColDef,
	EditableCallbackParams,
	GetContextMenuItemsParams,
	GridApi,
	MenuItemDef,
	ValueGetterParams,
} from "ag-grid-community";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormsContext } from "../../context/FormsContext";
import { LazyLoading } from "@/components/loaders/Loader/LazyLoading";
import { ErrorModal } from "@dexteel/mesf-core";
import { ColorCircleRenderer } from "./columns-table/color-column ";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
import { ButtonToShowOptionsQuestions } from "./columns-table/ButtonToShowOptionsQuestions";
import { NewAndEditSectionModal } from "./sections-modals/modal-new-and-edit-section";
import { Section } from "../../models/Section";
import { INITIAL_VALUES_SECTION } from "../../models/SectionInitialValues";
import { DeleteSectionDialog } from "./sections-modals/modal-delete-section";
import { QuestionsAndOptionsTable } from "../formQuestionsAndOptions/formQuestionsAndOptionsTable";
import { useClone } from "../../hooks/useClone";

const useStyles = makeStyles((theme) => ({
	root: {
		"& h3": {
			display: "inline-block",
			marginTop: 0,
			paddingBotton: 0,
			paddingLeft: 20,
			border: "none",
			userSelect: "none",
		},
		"& .content-wrapper": {
			border: "none",
		},
		"& .ag-icon-menu": {
			display: "none",
		},
		"& .ag-root-wrapper": {
			borderRadius: 3,
		},
		"& .ag-header-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 10,
		},
		"& .ag-header-cell-label": {
			justifyContent: "center",
		},
		"& .ag-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 12,
			lineBreak: "auto",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			border: "0 !important",
			wordBreak: "break-word",
		},
		"& .ag-comments": {
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
		},
		"& .ag-selection-checkbox": {
			marginLeft: "10px !important",
		},
	},
	relative: {
		position: "relative",
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalTitleCloseWO: {
		backgroundColor: "#28a745",
		color: "white",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	responsive: {
		[theme.breakpoints.up("md")]: {
			padding: "0 10px 0 0 !important",
			marginTop: "0 !important",
		},
	},
	responsiveTwo: {
		[theme.breakpoints.up("md")]: {
			padding: "0 0 0 10px !important",
			marginTop: "0 !important",
		},
	},
	responsiveThree: {
		[theme.breakpoints.up("md")]: {
			padding: "0 !important",
		},
	},
	responsiveFour: {
		[theme.breakpoints.up("md")]: {
			padding: "0 10px 0 0 !important",
		},
	},
	responsiveFive: {
		[theme.breakpoints.up("md")]: {
			padding: "0 0 0 10px !important",
		},
	},
}));

type Props = {
	show: boolean;
	onHide: (onHide: boolean) => void;
	searchFormSections: Function;
	searchForms: Function;
	isSectionsLoading: boolean;
};

export const FormSectionsTable = ({
	show,
	onHide,
	searchFormSections,
	isSectionsLoading,
	searchForms,
}: Props) => {
	const [gridApi, setGridApi] = useState<GridApi | null>(null);
	const classes = useStyles();
	const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("md");
	const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
	const [error, setError] = useState<string>("");
	const [shouldUpdateWhenExit, setShouldUpdateWhenExit] = useState(false);
	const [newMode, setNewMode] = useState<boolean>(false);

	const [openNewEditSection, setOpenNewEditSection] = useState(false);
	const [openDeleteSection, setOpenDeleteSection] = useState(false);
	const [openQuestionsAndOptionsTable, setOpenQuestionsAndOptionsTable] =
		useState(false);

	const {
		state: { formSectionsList, FormName, sectionSelectedInTable },
		actions: {
			setSectionSelectedInTable,
			setFormId,
			setSectionId,
			setSectionName,
		},
	} = useFormsContext();

	const loadingOverlayComponent = useMemo<any>(() => {
		return LazyLoading;
	}, []);

	const { CloneSection } = useClone();

	const handleClone = (SectionId: number) => {
		CloneSection({ SectionId, searchFormSections, setShouldUpdateWhenExit });
	};

	const defaultColDef = useMemo<ColDef>(() => {
		return {
			sortable: true,
			wrapText: true,
			autoHeight: true,
			wrapHeaderText: true,
		};
	}, []);

	const onActionsClick = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		params: EditableCallbackParams,
	) => {
		(params.api as any).contextMenuFactory.showMenu(
			params.node,
			params.column,
			undefined,
			e,
		);
	};

	const handleCancel = () => {
		onHide(shouldUpdateWhenExit);
	};

	const getContextMenuItems = useCallback(
		(params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
			const data: Section = params.node?.data;
			const result: (string | MenuItemDef)[] = [];

			if (data) {
				{
					result.push(
						// "separator",
						{
							name: "<strong>Edit</strong>",
							action: () => {
								setNewMode(false);
								setSectionSelectedInTable(data);
								setSectionId(data.SectionId);
								setSectionName(data.SectionName);
								setOpenNewEditSection(true);
							},
							icon: '<i class="fas fa-edit"></i>',
						},
					);
				}
			}

			if (data) {
				result.push({
					name: "New",
					action: () => {
						setSectionSelectedInTable(INITIAL_VALUES_SECTION);
						setNewMode(true);
						setSectionId(null);
						setSectionName("");
						setOpenNewEditSection(true);
					},
					icon: '<i class="fas fa-plus"></i>',
				});
			}

			if (data) {
				result.push({
					name: "Content",
					action: () => {
						setNewMode(false);
						setSectionSelectedInTable(data);
						setSectionId(data.SectionId);
						setSectionName(data.SectionName);
						setOpenQuestionsAndOptionsTable(true);
					},
					icon: '<i class="fas fa-table"></i>',
				});
			}

			if (data) {
				{
					result.push(
						// "separator",
						{
							name: "Duplicate Section",
							action: () => {
								setSectionSelectedInTable(data);
								handleClone(data.SectionId as number);
							},
							icon: '<i class="fas fa-clone"></i>',
						},
					);
				}
			}

			if (data) {
				{
					result.push(
						// "separator",
						{
							name: "Delete",
							action: () => {
								setNewMode(false);
								setOpenDeleteSection(true);
								setSectionSelectedInTable(data);
							},
							icon: '<i class="fas fa-trash"></i>',
						},
					);
				}
			}

			const addNewSection: (string | MenuItemDef)[] = [];

			if (!data) {
				addNewSection.push({
					name: "New",
					action: () => {
						setSectionSelectedInTable(INITIAL_VALUES_SECTION);
						setNewMode(true);
						setSectionId(null);
						setSectionName("");
						setOpenNewEditSection(true);
					},
					icon: '<i class="fas fa-plus"></i>',
				});
			}

			return data ? result : addNewSection;
		},
		[formSectionsList],
	);

	useEffect(() => {
		if (show) {
			setShouldUpdateWhenExit(false);
			searchFormSections();
			setColumnDefs([]);
			const newColumns: ColDef[] = [];

			newColumns.push({
				field: "SectionName",
				headerName: "Section Name",
				sortable: true,
				flex: 4,
				minWidth: 130,
				cellRenderer: (params: EditableCallbackParams) => {
					return (
						<Grid
							container
							style={{
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Grid
								item
								xs={12}
								md={12}
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
								title={params.data?.Subtitle}
							>
								{params.data?.SectionName}
							</Grid>
						</Grid>
					);
				},
			});
			newColumns.push({
				field: "BackgroundColor",
				headerName: "Color",
				sortable: false,
				flex: 2,
				minWidth: 50,
				maxWidth: 50,
				cellRendererFramework: ColorCircleRenderer,
			});
			newColumns.push({
				field: "",
				headerName: "Questions / Options",
				sortable: false,
				flex: 4,
				minWidth: 150,
				maxWidth: 150,
				cellRenderer: (params: EditableCallbackParams) =>
					ButtonToShowOptionsQuestions({
						params,
						setOpenQuestionsAndOptionsTable,
					}),
			}),
				newColumns.push({
					headerName: "",
					flex: 2,
					minWidth: 65,
					maxWidth: 65,
					sortable: false,
					cellClass: "ag-columns",
					wrapText: false,
					autoHeight: false,
					cellRenderer: (params: EditableCallbackParams) => {
						return (
							<Grid
								container
								style={{
									height: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Grid
									item
									xs={12}
									md={12}
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Grid
										container
										spacing={1}
										style={{ justifyContent: "flex-end", minWidth: 30 }}
									>
										<Grid item xs={12} md={12}>
											<Button
												fullWidth
												style={{}}
												onClick={(e) => onActionsClick(e, params)}
											>
												<FormatListBulletedSharpIcon
													style={{ height: "auto" }}
													color="action"
												/>
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						);
					},
				});

			setColumnDefs(newColumns);
		}
	}, [show]);

	useEffect(() => {
		if (isSectionsLoading) {
			gridApi?.showLoadingOverlay();
		} else gridApi?.hideOverlay();
	}, [isSectionsLoading, formSectionsList, gridApi]);

	return (
		<>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={show}
				onClose={handleCancel}
				style={{ margin: "20px 25%" }}
				className={classes.root}
			>
				<Grid
					container
					justifyContent="center"
					style={{ height: "84vh", paddingBottom: 0 }}
				>
					<Grid
						item
						md={12}
						xs={12}
						style={{ padding: "0 20px", height: "6vh" }}
					>
						<Typography
							variant="h5"
							style={{ margin: "20px 0 -40px", fontWeight: 600 }}
						>
							{FormName} / Sections
						</Typography>
					</Grid>
					<DialogContent dividers style={{ height: "65vh" }}>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							className={classes.root}
						>
							<Grid item md={12} xs={12} className={classes.relative}>
								<Grid
									item
									md={12}
									xs={12}
									style={{
										height: "59vh",
										border: "4px solid #ccc",
										borderRadius: 10,
									}}
								>
									<div style={gridStyle} className="ag-theme-alpine">
										<AgGridReact
											rowData={formSectionsList}
											columnDefs={columnDefs}
											defaultColDef={defaultColDef}
											rowHeight={38}
											headerHeight={42}
											loadingOverlayComponent={loadingOverlayComponent}
											animateRows={true}
											getContextMenuItems={(e: any) => getContextMenuItems(e)}
											pagination={true}
											onRowDoubleClicked={(event) => {
												setSectionName(event.data?.SectionName);
												setSectionId(event.data?.SectionId);
												setSectionSelectedInTable(event.data);
												setFormId(event.data?.FormId);
												setOpenNewEditSection(true);
											}}
											rowSelection="single"
											onGridReady={(params) => setGridApi(params.api)}
										/>
									</div>
								</Grid>
								<NewAndEditSectionModal
									show={openNewEditSection}
									data={sectionSelectedInTable}
									newMode={newMode}
									onHide={(shouldUpdate: boolean) => {
										if (shouldUpdate) {
											searchFormSections();
											setShouldUpdateWhenExit(true);
										}
										setOpenNewEditSection(false);
									}}
								/>
								<DeleteSectionDialog
									show={openDeleteSection}
									searchData={searchFormSections}
									onHide={(shouldUpdate: boolean) => {
										if (shouldUpdate) {
											searchFormSections();
											searchForms();
										}
										setOpenDeleteSection(false);
									}}
								/>
								<QuestionsAndOptionsTable
									show={openQuestionsAndOptionsTable}
									onHide={(shouldUpdate: boolean) => {
										if (shouldUpdate) {
											searchFormSections();
											searchForms();
										}
										setOpenQuestionsAndOptionsTable(false);
									}}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<Grid item md={12} xs={12}>
						<DialogActions
							style={{
								height: "6vh",
								padding: "0px 24px",
								display: "flex",
								justifyContent: "flex-start",
							}}
						>
							<Grid item md={4} xs={4}>
								<Button
									fullWidth
									variant="contained"
									color="default"
									onClick={handleCancel}
								>
									Close
								</Button>
							</Grid>
						</DialogActions>
					</Grid>
					<ErrorModal error={error} onHide={() => setError("")} />
				</Grid>
			</Dialog>
		</>
	);
};
