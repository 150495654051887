import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { AgGridReact } from "ag-grid-react";
import {
	ColDef,
	EditableCallbackParams,
	GetContextMenuItemsParams,
	GridApi,
	MenuItemDef,
} from "ag-grid-community";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormsContext } from "../../context/FormsContext";
import { LazyLoading } from "@/components/loaders/Loader/LazyLoading";
import { ErrorModal } from "@dexteel/mesf-core";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
import { INITIAL_VALUES_OPTION } from "../../models/OptionInitialValue";
import { Option } from "../../models/Option";
import { useSearchQuestionOptions } from "../../hooks/useSearchQuestionOptions";
import { DeleteOptionDialog } from "./questions-options-modals/modal-delete-option";
import { NewAndEditOptionModal } from "./questions-options-modals/modal-new-and-edit-option";

type Props = {
	questionIdToSearchOptions: number;
	setOpenNewEditOption: (setter: boolean) => void;
	setOpenDeleteOption: (setter: boolean) => void;
	setNewOptionMode: (setter: boolean) => void;
	setShouldUpdateWhenExit: (shouldUpdate: boolean) => void;
	openNewEditOption: boolean;
	openDeleteOption: boolean;
	newOptionMode: boolean;
};

export const OptionsTable = ({
	setShouldUpdateWhenExit,
	questionIdToSearchOptions,
	setOpenNewEditOption,
	openNewEditOption,
	openDeleteOption,
	setOpenDeleteOption,
	newOptionMode,
	setNewOptionMode,
}: Props) => {
	const [gridApi, setGridApi] = useState<GridApi | null>(null);
	const gridStyle = useMemo(
		() => ({ height: "100%", width: "100%", padding: 10 }),
		[],
	);
	const [optionsColumnDefs, setOptionsColumnDefs] = useState<ColDef[]>([]);

	const {
		state: { optionSelectedInTable, questionOptionsList, questionList },
		actions: { setOptionSelectedInTable, setOptionId, setOptionText },
	} = useFormsContext();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const searchQuestionOptions = useSearchQuestionOptions({
		setIsLoading,
		setError,
		questionIdToSearchOptions,
	});

	const loadingOverlayComponent = useMemo<any>(() => {
		return LazyLoading;
	}, []);

	const defaultColDef = useMemo<ColDef>(() => {
		return {
			sortable: true,
			wrapText: true,
			autoHeight: true,
			wrapHeaderText: true,
		};
	}, []);

	const onActionsClick = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		params: EditableCallbackParams,
	) => {
		(params.api as any).contextMenuFactory.showMenu(
			params.node,
			params.column,
			undefined,
			e,
		);
	};

	const getOptionsContextMenuItems = useCallback(
		(params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
			const data: Option = params.node?.data;
			const result: (string | MenuItemDef)[] = [];

			if (data) {
				{
					result.push({
						name: "<strong>Edit</strong>",
						action: () => {
							setNewOptionMode(false);
							setOptionSelectedInTable(data);
							setOptionId(data.OptionId);
							setOptionText(data.OptionText);
							setOpenNewEditOption(true);
						},
						icon: '<i class="fas fa-edit"></i>',
					});
				}
			}

			if (data) {
				result.push({
					name: "New Option",
					action: () => {
						setOptionSelectedInTable(INITIAL_VALUES_OPTION);
						setNewOptionMode(true);
						setOptionId(null);
						setOptionText("");
						setOpenNewEditOption(true);
					},
					icon: '<i class="fas fa-plus"></i>',
				});
			}

			if (data) {
				{
					result.push(
						// "separator",
						{
							name: "Delete",
							action: () => {
								setNewOptionMode(false);
								setOpenDeleteOption(true);
								setOptionSelectedInTable(data);
							},
							icon: '<i class="fas fa-trash"></i>',
						},
					);
				}
			}

			const addNewOption: (string | MenuItemDef)[] = [];

			if (!data && questionIdToSearchOptions !== null) {
				addNewOption.push({
					name: "New",
					action: () => {
						setOptionSelectedInTable(INITIAL_VALUES_OPTION);
						setNewOptionMode(true);
						setOptionId(null);
						setOptionText("");
						setOpenNewEditOption(true);
					},
					icon: '<i class="fas fa-plus"></i>',
				});
			}

			return data ? result : addNewOption;
		},
		[questionOptionsList],
	);

	const renderColumns = () => {
		setOptionsColumnDefs([]);
		const newColumns: ColDef[] = [];
		newColumns.push({
			field: "OptionText",
			headerName: "Option",
			sortable: true,
			flex: 3,
			minWidth: 180,
		}),
			newColumns.push({
				field: "IsEnabled",
				headerName: "Is Enabled",
				sortable: false,
				flex: 1,
				minWidth: 130,
				valueFormatter: function (params) {
					return params.value ? "Yes" : "No";
				},
			}),
			newColumns.push({
				field: "OptionTypeCode",
				headerName: "Option Type",
				sortable: false,
				flex: 1,
				minWidth: 100,
				maxWidth: 100,
				valueFormatter: (params) => {
					switch (params.value) {
						case "O":
							return "OK";
						case "W":
							return "Warning";
						case "A":
							return "Alert";
						default:
							return params.value;
					}
				},
			});
		newColumns.push({
			headerName: "",
			flex: 1,
			minWidth: 65,
			maxWidth: 65,
			cellClass: "ag-columns",
			wrapText: false,
			autoHeight: false,
			cellRenderer: (params: EditableCallbackParams) => {
				return (
					<Grid
						container
						style={{
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Grid
							item
							xs={12}
							md={12}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Grid
								container
								spacing={1}
								style={{ justifyContent: "flex-end", minWidth: 30 }}
							>
								<Grid item xs={12} md={12}>
									<Button
										fullWidth
										style={{}}
										onClick={(e) => onActionsClick(e, params)}
									>
										<FormatListBulletedSharpIcon
											style={{ height: "auto" }}
											color="action"
										/>
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				);
			},
		});
		setOptionsColumnDefs(newColumns);
	};

	useEffect(() => {
		renderColumns();
	}, [questionList]);

	useEffect(() => {
		if (isLoading) {
			gridApi?.showLoadingOverlay();
		} else gridApi?.hideOverlay();
	}, [isLoading, questionOptionsList, gridApi]);

	useEffect(() => {
		if (questionIdToSearchOptions) searchQuestionOptions();
	}, [questionIdToSearchOptions]);

	return (
		<>
			<Grid
				item
				md={6}
				xs={6}
				style={{ height: "58vh", border: "4px solid #ccc", borderRadius: 10 }}
			>
				<div style={gridStyle} className="ag-theme-alpine">
					<AgGridReact
						rowData={questionOptionsList}
						columnDefs={optionsColumnDefs}
						defaultColDef={defaultColDef}
						rowHeight={38}
						headerHeight={42}
						loadingOverlayComponent={loadingOverlayComponent}
						animateRows={true}
						getContextMenuItems={(e: any) => getOptionsContextMenuItems(e)}
						pagination={true}
						onRowDoubleClicked={(event) => {
							setNewOptionMode(false);
							setOptionSelectedInTable(event.data);
							setOptionId(event.data?.OptionId);
							setOptionText(event.data?.OptionText);
							setOpenNewEditOption(true);
						}}
						rowSelection="single"
						onGridReady={(params) => setGridApi(params.api)}
					/>
				</div>
			</Grid>
			<NewAndEditOptionModal
				show={openNewEditOption}
				data={optionSelectedInTable}
				questionIdToSearchOptions={questionIdToSearchOptions as number}
				newMode={newOptionMode}
				setShouldUpdateWhenExit={setShouldUpdateWhenExit}
				onHide={(shouldUpdate: boolean) => {
					if (shouldUpdate) {
						searchQuestionOptions();
					}
					setOpenNewEditOption(false);
				}}
			/>
			<DeleteOptionDialog
				show={openDeleteOption}
				setShouldUpdateWhenExit={setShouldUpdateWhenExit}
				onHide={(shouldUpdate: boolean) => {
					if (shouldUpdate) {
						searchQuestionOptions();
					}
					setOpenDeleteOption(false);
				}}
			/>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
